import React from 'react';
import styled from 'styled-components';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';

import { PageProps } from 'gatsby';
import { SEO } from '../SEO';
import { Site } from '../components/Site';
import { Main } from '../components/Styled';

export default (props: PageProps) => {
  return (
    <Site seo={SEO.privacy} {...props}>
      <Main>
        <Container>
          <PrivacyPolicy>
            <SectionHeading>daisee Privacy Policy</SectionHeading>
            <P>This following document sets forth the Privacy Policy for the daisee website daisee.com</P>
            <P>
              daisee is committed to providing you with the best possible customer service experience. daisee is bound
              by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of
              individuals.
            </P>
            <Heading>Collection of your personal information</Heading>
            <P>
              There are many aspects of the site which can be viewed without providing personal information, however,
              for access to future daisee customer support features you may be required to submit personally
              identifiable information. This may include, but is not limited to, a unique username and password, or
              provide sensitive information in the recovery of your lost password.
            </P>
            <Heading>Sharing of your personal information</Heading>
            <P>
              We may occasionally hire other companies to provide services on our behalf including, but not limited to,
              handling customer support enquiries or marketing activities. Those companies will be permitted to obtain
              only the personal information they need to deliver the service. daisee takes all reasonable steps to
              ensure that these organisations are bound by confidentiality and privacy obligations in relation to the
              protection of your personal information.
            </P>
            <Heading>Use of your personal information</Heading>
            <P>
              For each visitor to reach the site, we expressly collect the following non-personally identifiable
              information, including but not limited to browser type, version and language, operating system, pages
              viewed while browsing the Site, page access times and referring website address. This collected
              information is used solely internally for the purpose of gauging visitor traffic, trends and delivering
              personalized content to you while you are at this Site.
            </P>

            <P>
              From time to time, we may use customer information for new, unanticipated uses not previously disclosed in
              our privacy notice. If our information practices change at some time in the future we will use for these
              new purposes only, data collected from the time of the policy change forward will adhere to our updated
              practices.
            </P>
            <Heading>Changes to this Privacy Policy</Heading>
            <P>
              daisee reserves the right to make amendments to this Privacy Policy at any time. If you have objections to
              the Privacy Policy, you should not access or use the Site.
            </P>
            <Heading>Accessing Your Personal Information</Heading>
            <P>
              You have a right to access your personal information, subject to exceptions allowed by law. If you would
              like to do so, please let us know. You may be required to put your request in writing for security
              reasons. daisee reserves the right to charge a fee for searching for, and providing access to, your
              information on a per request basis.
            </P>
            <Heading>Contacting us</Heading>
            <P>
              daisee welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy
              Policy and would like further information, please contact us via our contact form on daisee.com
            </P>
            <PrivacyFooter>
              <P>Updated November 2020</P>
              <P>ABN 43 618 989 549</P>
              <P>E: info@daisee.com</P>
            </PrivacyFooter>
          </PrivacyPolicy>
        </Container>
      </Main>
      <Footer />
    </Site>
  );
};

const SectionHeading = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: -2px;
  color: ${({ theme }) => theme.colors.grey400};
  padding: 0 0 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  line-height: 1.2;

  @media all and (max-width: 48em) {
    font-size: 3rem;
  }

  @media all and (max-width: 28.125em) {
    font-size: 2.5rem;
  }
`;

const PrivacyPolicy = styled.article`
  padding: 9rem 1.5rem 0;
`;

const Heading = styled.h2`
  margin: 3rem 0 1.5rem;
  line-height: 1.2;
`;

const P = styled.p`
  font-weight: 500;
  line-height: 1.9;
  color: ${({ theme }) => theme.colors.grey700};
  margin-bottom: 1.5rem;
`;

const PrivacyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  flex-wrap: wrap;
`;
